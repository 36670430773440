import { useStaticQuery, graphql } from "gatsby"
import React, { Fragment, useState } from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Grid, Row, Col } from 'react-bootstrap';
import style from '../styles/contact_section.module.scss'
import { FaMobile, FaBuilding, FaRegEnvelope } from 'react-icons/fa';
import Obfuscate from 'react-obfuscate';
import 'whatwg-fetch';
import Cookies from 'js-cookie';


const ContactSection = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phoneNumber
            officePhoneNumber
            email
          }
        }
      }
    `
  )


  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const portalId = '7859192'
  const formId = 'c6e2852f-86b5-44a2-adff-41478c85caaf'
  const formSuccessMessage = 'Your form submission has been received!'
  const formErrorMessage = 'Could not submit form.  Please make sure everything is entered correctly.'

  const submitForm = e => {
    if (e) e.preventDefault();

    // In Gatsby, we're building our static site in a Node
    // environment, rather than a browser environment, so anything
    // browser related needs to be wrapped in some version of the
    // following isBrowser variable.

    // What we're getting here is some contextual data to send
    // along to Hubspot so it can organize and track forms as
    // they relate to specific users in the CRM.  The Hubspot
    // plugin we installed earlier provides this hutk value as a cookie.
    // pageName & pageUri should be pretty self explanatory.

    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    setLoading(true);

    // This object shape is what's required in the Hubspot API
    // documentation.  There's an additional legalConsentOptions object
    // I've left out here, but would be important if you're working on a
    // legitimate organization's site & they want to be safe from any sort
    // of GDPR guff.
    //
    // Read more here:
    // https://developers.hubspot.com/docs/methods/forms/submit_form_v3

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'lastname',
          value: lastname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'phone',
          value: phone,
        },
        {
          name: 'message',
          value: message,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    // These specific headers are required for whatever reason,
    // so don't forget to include them.

    function checkStatus(response) {
      if (response.status >= 200 && response.status < 300) {
        return response
      } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
      }
    }

    function parseJSON(response) {
      return response.json()
    }

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(function(data) {
        console.log('request succeeded with JSON response', data)
        console.log('*** HUBSPOT OK ***', data);
        setSuccess(true);
        setError(false);
        setLoading(false);
        setFirstname('');
        setLastname('');
        setEmail('');
        setPhone('');
        setMessage('');
      }).catch(function(error) {
        setSuccess(false);
        setError(true);
        setLoading(false);
        console.log('*** HUBSPOT ERROR ***', error);
        scrollTo('#formErrorButton')
      })
  };

  return (
    <div id="contact" className={style.contactSection}>
      <Grid>
          <Fragment>
            <Row>
              <Col md={12}>
                <div className={`text-center ${style.sectionTitle}`}>
                  <h2 className="wow">contact me</h2>
                </div>
                <div className={style.descriptionSection}>
                  <p>
                    I am here to help with all your real estate needs and questions. Let's start a conversation.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} xs={12} className={`text-center ${style.contactSmallBox}`}>
                <div className={style.phoneTop}>
                  <FaBuilding size=".7em"/>
                  office
                </div>
                <div className={style.phoneBottom}>
                  <Obfuscate tel={data.site.siteMetadata.officePhoneNumber} />
                </div>
              </Col>
              <Col md={4} sm={12} xs={12} className={`text-center ${style.contactSmallBox}`}>
                <div className={style.phoneTop}>
                  <FaMobile size=".7em"/>
                  mobile
                </div>
                <div className={style.phoneBottom}>
                  <Obfuscate tel={data.site.siteMetadata.phoneNumber} />
                </div>
              </Col>
              <Col md={4} sm={12} xs={12} className={`text-center ${style.contactSmallBox}`}>
                <div className={style.phoneTop}>
                  <FaRegEnvelope size=".7em"/>
                  e-mail
                </div>
                <div className={style.phoneBottom}>
                  <Obfuscate email={data.site.siteMetadata.email}/>
                </div>
              </Col>
            </Row>
            {success && (
              <Row>
                <Col md={12}>
                  <h3 className="text-center">🎉🎉🎉 Thank You! 🎉🎉🎉</h3>
                  <p className="text-center">{formSuccessMessage}</p>
                </Col>
              </Row>
            )}
            {!success && (
              <Row>
                <Col md={6} mdOffset={3}>
                  <div className={style.formWrapper}>
                    <form id="contact-form" noValidate onSubmit={submitForm} disabled={loading}>
                      {error && (
                        <Row>
                          <Col md={12} className="text-center">
                            <p id="formErrorButton" className={`btn btn-danger text-center ${style.formErrorButton}`}>{formErrorMessage}</p>
                          </Col>
                        </Row>
                      )}
                      <div className="form-group">
                        <Row>
                          <Col md={6}>
                            <input type="text" className={style.siteInput} placeholder="What is your first name?" required onInput={e => { setFirstname(e.target.value) } } />
                          </Col>
                          <Col md={6}>
                            <input type="text" className={style.siteInput} placeholder="What is your last name?" required onInput={e => setLastname(e.target.value)} />
                          </Col>
                        </Row>
                      </div>
                      <div className="form-group">
                        <input type="email" className={style.siteInput} placeholder="Your e-mail" required onInput={e => setEmail(e.target.value)}/>
                      </div>
                      <div className="form-group">
                        <input type="text" className={style.siteInput} placeholder="Your phone number" required onInput={e => setPhone(e.target.value)}/>
                      </div>
                      <div className="form-group">
                        <textarea placeholder="Write your message here..." required onInput={e => setMessage(e.target.value)} />
                      </div>
                      <p>We need the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.
                      </p>
                      <div className="text-center">
                        <input type="submit" className={style.siteBtn} value="Send message" />
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            )}
          </Fragment>
      </Grid>
    </div>
  )
}

export default ContactSection
