import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Grid, Row, Col } from 'react-bootstrap';
import Img from "gatsby-image"
import aboutStyle from '../styles/about.module.scss'
//import { FaCopyright } from 'react-icons/fa';

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutTitleQuery {
      site {
        siteMetadata {
          name
          about_image_alt
        }
      },
      aboutImage: file(relativePath: { eq: "Lauren-057.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 330, maxHeight: 330, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutSign: file(relativePath: { eq: "about-sign.png" }) {
        childImageSharp {
          fixed(width: 300, height: 68) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div id="about" className={aboutStyle.aboutSection}>
      <div className="container">
          <div className="row">
              <div className="col-md-4 col-md-offset-1">
                  <div className={aboutStyle.aboutImage}>
                      <Img fluid={data.aboutImage.childImageSharp.fluid} alt={data.site.siteMetadata.about_image_alt}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className={aboutStyle.aboutTextBox}>
                      <h2 className="wow">Hi, I’m Lauren Hughes!</h2>
                      <div className={aboutStyle.aboutMe}>
                          <p>I’m a top-rated Real Estate Agent in Boulder, CO and nearby areas,
                          providing home-buyers and sellers with professional, responsive and attentive
                          real estate services. Want an agent who'll really listen to what you want in a home?
                          I know exactly how to get your offer accepted in a competitive market.
                          Need an agent who knows how to effectively market your home so it sells quickly? I have
                          a proven process guaranteed to sell your home for the highest possible price.
                          Give me a call! I'm eager to help and would love to talk to you.</p>
                      </div>
                      <div className={aboutStyle.signBox}>
                          <p>Lauren Hughes, Real Estate Agent</p>
                          <div className={aboutStyle.signature}>
                              <Img fixed={data.aboutSign.childImageSharp.fixed} alt={data.site.siteMetadata.name}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default About