import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Grid, Row, Col } from 'react-bootstrap';
import style from '../styles/question_section.module.scss'
import BackgroundImage from 'gatsby-background-image'
import scrollTo from 'gatsby-plugin-smoothscroll';

const QuestionSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "bg-2.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  //color is $accent-color from variables.scss.
  //Very minor TODO: Is there a way to use that variable here somehow?
  //B2 = 70% in Hex.  70% of 255.
  const backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    `linear-gradient(90deg, #036ffcb2 0%, #036ffcb2 50%)`
  ].reverse()

  return (
    <BackgroundImage
      Tag="div"
      className={style.questionSection}
      fluid={backgroundFluidImageStack}
    >
      <Grid>
        <Row>
          <Col md={12}>
            <div className={style.questionText}>
              <h3>
                Looking for your best home <br/> in Colorado? <a onClick={() => scrollTo('#contact')}>Ask me how!</a></h3>
            </div>
          </Col>
        </Row>
      </Grid>
    </BackgroundImage>
  )
}

export default QuestionSection
