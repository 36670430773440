import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import QuestionSection from "../components/question_section"
import ContactSection from "../components/contact_section"

const IndexPage = () => (
  <Layout>
    <SEO title="Lauren Hughes Sells" />
    <Hero/>
    <About/>
    <QuestionSection/>
    <ContactSection/>
  </Layout>
)

export default IndexPage
