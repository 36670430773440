import { graphql, useStaticQuery, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Grid, Row, Col } from 'react-bootstrap';
import style from '../styles/hero.module.scss'
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import BackgroundImage from 'gatsby-background-image'
import scrollTo from 'gatsby-plugin-smoothscroll';

const Hero = () => {

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Lauren-029.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        site {
          siteMetadata {
            name
            social {
              facebook
              youtube
            }
          }
        }
      }
    `
  )

  //color is $accent-color from variables.scss.
  //Very minor TODO: Is there a way to use that variable here somehow?
  //4D = 30% of 255 in Hex.
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(90deg, #1919194D 0%, #1919194D 50%)`
  ].reverse()

  return (
    <BackgroundImage
      Tag="div"
      className={style.firstScreen}
      fluid={backgroundFluidImageStack}
    >
        <Grid>
            <Row>
                <Col md={12}>
                    <div className={style.greetingsBox}>
                        <div className={style.greetingsBoxHead}>
                            <div className={style.mainName}>
                                <h1 className="wow">{data.site.siteMetadata.name}</h1>
                            </div>
                            <div className={style.mainSocial}>
                              <a className={style.socialFacebook} target="_blank" rel="noreferrer" href={data.site.siteMetadata.social.facebook}><FaFacebook/></a>
                              <a className={style.socialYoutube} target="_blank" rel="noreferrer" href={data.site.siteMetadata.social.youtube}><FaYoutube/></a>
                            </div>
                        </div>
                        <div className={style.greetingsBoxBody}>
                            <p>Experienced Colorado Real Estate Agent</p>
                            <p>Contact me to find the home of your dreams!</p>
                        </div>
                        <div className={style.greetingsBoxFooter}>
                            <button id="free-consultation" className={style.freeConsultationBtn} onClick={() => scrollTo('#contact')}>
                              Get FREE consultation
                            </button>
                            <div className={style.consultationFormWrapper}>
                                <form className={style.consultationForm}>
                                    <input className="input-phone" type="tel" placeholder="Type your phone number here" />
                                    <input type="submit" value="Call me" className={style.siteBtn} />
                                </form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Grid>
    </BackgroundImage>
  )
}

export default Hero
